







import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'DeskCore',
  components: {
    AsyncCoreList: () => import('../async/core-list.vue')
  },
  setup() {
    const show = ref<boolean>(false)
    const handleSetShow = (state: boolean) => (show.value = state)
    return {
      show,
      handleSetShow
    }
  }
})
